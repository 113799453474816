<template>
  <Dialog :close-on-outside-click="false">
    <div class="flex flex-col p-md">
      <img
        v-if="$siteIdent.startsWith('minilu')"
        src="@/assets/images/minilu/minilu_autologout.svg"
        class="object-cover object-center h-full md:block w-full max-h-[200px] flex-shrink mt-sm md:mt-lg"
      />
      <img
        v-else
        src="@/assets/images/vdv/vdv_autologout.svg"
        class="object-cover object-center h-full md:block w-full max-h-[200px] flex-shrink mt-sm md:mt-lg"
      />

      <Headline
        :headline="t('Oops!')"
        level="h4"
        class="max-w-full text-center mt-sm md:mt-md"
      />

      <div class="px-0 mx-auto text-center md:px-xl mt-sm">
        {{ t('account.session.autologout.dialog.warning') }}
      </div>

      <BasicButton
        full-width
        class="mx-auto w-[200px] mt-sm md:mt-md"
        :btn-style="EButtonStyle.DEFAULT"
        :label="t('account.session.autologout.dialog.confirm')"
        @click="useDialogStore().closeDialog({ url: '/login' })"
      />
      <div
        class="flex gap-2 mx-auto cursor-pointer mt-sm md:mt-md place-items-center text-primary-base font-deco-alt text-alt-md"
        @click="useDialogStore().closeDialog()"
      >
        <span class="anim__underline-link anim-link">
          {{ t('404.toHome') }}
        </span>
        <Icon icon-class="fas fa-chevron-right" />
      </div>
      <div class="mt-sm md:mt-md"></div>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/global/dialogFrame.vue';
import { BasicButton, Headline, Icon } from '@/complib';
import { EButtonStyle } from '~/@types/basic-button';
import { useDialogStore } from '~/stores/useDialogStore';

const { t } = useTrans();
useDialogStore().setHeadline('account.session.autologout.dialog.headline');
</script>
